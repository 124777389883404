import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Section from "../components/Section"
import Figure from "../components/Figure"
import { H1, H2, H3, P } from "../components/VerticalRhythm"
import { Flex, Box } from "rebass"

import PictureSet from "../components/PictureSet"

import breakpoints from "../theme/breakpoints"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Kontakt"
      keywords={[`Zahnräuber`, `Doris Gunselmann`, `Karl Anders`]}
    />
    <Figure caption="">{/* <PictureSet imgObj={} /> */}</Figure>
    <Section></Section>
  </Layout>
)

export default IndexPage
